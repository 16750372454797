<template>
    <div>
      <h2 class="pa-md-5 mx-lg-auto primary--text text-uppercase text-left pb-6" :class="$vuetify.breakpoint.mdAndDown ? 'title text-h6': 'title text-h6'">{{ title }}</h2>
      
    
      <div style="border:4px solid #5167c2 ;"></div>
    </div>
  </template>
  
  <script>
  export default {
    name : 'TitleComponent',
    props : ['title'],
    mounted() {
      console.log(this.$props);
    }
  }
  </script>
  
  <style lang="css">
  
  
  </style>