<template>
  <div class="container">
    
    <p class="text-sm-caption text-md-h6 text-justify">
      Since the foundation of town of San Jose on April 26, 1765 up to the present time, there are already One Hundred Forty Six(146) appointed or 
      elected heads (Capitanes, Alcaldes, Gobernadorcillos and Presidentes) of this Municipality. Based on the record, the list of  
      "Cabezas", "Gobernadorcillos", "Presidentes Local", "Municipal Presidentes" and "Municipal Mayors" of San Jose contained the following names: 
    </p>
    <v-divider></v-divider>   
    <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="15"
    class="elevation-1"
    >
    </v-data-table>  
  </div>
</template>

<script>
import axios from 'axios' 

export default {
  name : 'Mayors',
  components : {
   
  },
  data() {
    return {
      items:[],
       headers: [  
        { text: 'YEAR',   align: 'start', value: 'Year'  },
        { text: 'NAME',   align: 'start', value: 'Name'  },
        { text: 'TITLE', value: 'Title' },
      ], 
    }
  },
  mounted(){
    this.getAllRecords();
    
  },
  methods:{
    getAllRecords:function(){
      axios.get( this.$api_url+`/getAllRecords/mayors/where ID > 0/ID desc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.items = response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },
  },
}


</script>

<style lang="">
  
</style>