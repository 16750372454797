<template>
  <div class="barangays">
    
   
    <v-data-table
    :headers="headers"
    :items=" BarangayData"
    :items-per-page="15"
    class="elevation-1"
    >
    </v-data-table>  
   
    
  </div>
  
 
</template>

<script>
import axios from 'axios' 

export default {
  name : 'Barangays',
  components: {
  
  },

data () {
    return {
        BarangayData: [],
        headers: [
            { text: 'BARANGAY NAME', align: 'start', value: 'Name'},
            { text: 'SETTLEMENT', value: 'Settlement' },
            { text: 'BARANGAY CAPTAIN', value: 'Captain' },
            { text: 'PSGC', value: 'PSGC' },
            { text: 'POPULATION', value: 'Population' },
            { text: 'CONTACT NO/S', value: 'ContactNumbers' },
            { text: 'EMAIL', value: 'Email' },
        ],
     
    }
  },

    mounted(){
      //alert(this.items);
      this.BarangayData=this.getAllRecords(`/getAllRecords/barangays/where ID > 0 /Name asc`);
      //alert(THIS.TableData);
    },

     methods:{

     
      getAllRecords:function(query){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.BarangayData=response.data;
              //return this.items
            }
      })
      .catch(error => { console.log(error)})
    },  
  }

}
</script>

<style>
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>