<template>
  <div >
     <v-container>
    <p  class="text-sm-caption text-md-h4 text-justify font-weight-bold">Location, Land Area and political subdivisions</p>
        <p class="text-sm-caption text-md-h5 text-justify">San Jose is one of the 31 municipalities of Province of Batangas.
        The&nbsp;It is geographically located at 13°52'38″N 121°06'18″E. It is about 85 km from Metro Manila
        and about 13 km from the Port of Batangas. San Jose is strategically located between two (2) cities 
        and three (3) municipalities. It is bounded on the north by Lipa City, on the south by Batangas City,
        on the east by the municipality of Ibaan, 
        on the west by Cuenca and on the southwest by Municipality of San Pascual.  
        It is served by the existing STAR Toll way with Exit/Entry points at the city of Lipa 
        and Municipality of Ibaan. The municipality has a total land area of 5,954.3 hectares. 
        It comprises 1.88% of the total land area of the province which is 316,518 hectares.
        </p>
        <p class="text-sm-caption text-md-h5 text-justify">San Jose is politically divided in Thirty Three (33) barangays. 
        The urban barangay has a combined area of 115.5361 hectares and 
        is equivalent to 9.94% of the whole municipality. Those are barangay Poblacion, 1,2,3,4 and Taysan.
        The remaining Twenty eight (28) barangays are considered as rural barangays. 
        Their combined land area constitute to 5,838.7639 or 98.06% of the total land area of the municipality.
        </p>
        <p class="text-sm-caption text-md-h4 text-justify font-weight-bold">Geology</p>
        <p class="text-sm-caption text-md-h5 text-justify">There was no recorded and primary evidence of rock formation in the area per Mines and Geo Sciences Bureau of the DENR.</p>
        
        <v-row> 
          <v-col cols="6">   

            <v-card
                class="mx-auto"
                :max-width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
                :max-height="300"
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 125"
                :width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
                v-for="(GeologicMap, i) in this.itemGeologicMap"   
                :key="i"
            >
              <v-card-title class="text-subtitle-1 font-weight-bold " >
                {{GeologicMap.Name}}
              </v-card-title> 
            
              <EnlargeableImage
                :src="$api_url+GeologicMap.Url" 
                :src_large="$api_url+GeologicMap.Url" 
                :src_height="$vuetify.breakpoint.mdAndUp ? 250 : 125"
                :src_width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
              />
            </v-card>
          </v-col>
          <v-col cols="6">  
            
            <v-card
                class="mx-auto"
                :max-width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
                :max-height="300"
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 125"
                :width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
                v-for="(GeoHazardMap, i) in this.itemGeoHazardMap"   
                :key="i"
            >
              <v-card-title class="text-subtitle-1 font-weight-bold " >
                {{GeoHazardMap.Name}}
              </v-card-title> 
             
              <EnlargeableImage
                :src="$api_url+GeoHazardMap.Url" 
                :src_large="$api_url+GeoHazardMap.Url" 
                :src_height="$vuetify.breakpoint.mdAndUp ? 250 : 125"
                :src_width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
              />
             
            </v-card>
           
          </v-col>  
        </v-row>  
       </v-container>

      <v-container>
       <v-row> 
        <p class="text-sm-caption text-md-h4 text-justify font-weight-bold">Topography and Slope</p>
        <p class="text-sm-caption text-md-h5 text-justify">The municipality is slighly plain, with highest elevation of only 400 feet at the northwestern
        portion of the municipality, specifically Bigain Hill. The municipality is being drained by several rivers and creeks.
        Slope ranges from 0.3 to 8-15%. The only area that can be identified as steeply hilly is the Bigain Hill located at 
        Bigain 2 that has a slope of 8-15%.
        </p>
       
         <v-card
            class="mx-auto"
            :max-width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
            :max-height="400"
            :height="$vuetify.breakpoint.mdAndUp ? 300 : 125"
            :width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
             v-for="(SlopeMap, i) in this.itemSlopeMap"   
            :key="i"
        >
          <v-card-title class="text-subtitle-1 font-weight-bold " >
            {{SlopeMap.Name}}
          </v-card-title> 
         
          <EnlargeableImage 
            :src="$api_url+SlopeMap.Url" 
            :src_large="$api_url+SlopeMap.Url" 
            :src_height="$vuetify.breakpoint.mdAndUp ? 300 : 125"
            :src_width="$vuetify.breakpoint.mdAndUp ? 500 : 175"
           
          />
        </v-card>
        </v-row>
        </v-container>
   
  </div>
</template>

<script>
import axios from 'axios' 
import EnlargeableImage from '@/components/EnlargeableImage';

export default {
  name : 'Geography',
  components : {
    EnlargeableImage 
  },
  data() {
    return {
      itemGeologicMap:[],
      itemGeoHazardMap:[],
      itemSlopeMap:[],
       
    }
  },
  mounted(){
    this.getAllRecords('GeologicMap');
    this.getAllRecords('GeoHazardMap');
    this.getAllRecords('SlopeMap');
    
  },
  methods:{
    getAllRecords:function(type){
      var MapName=''
      if(type=='GeologicMap'){
        MapName='Geologic Map'
      }
      else if (type=='GeoHazardMap'){
        MapName='Geo Hazard Map'
      }
      else if (type=='SlopeMap'){
        MapName='Slope Map'
      }
      axios.get( this.$api_url+`/getAllRecords/maps/where Name='`+MapName+`'/ID desc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if (type=='GeologicMap'){
                this.itemGeologicMap = response.data;
                //alert(this.itemGeologicMap)
              }
              else if (type=='GeoHazardMap'){
                this.itemGeoHazardMap = response.data;
              }
              else if (type=='SlopeMap'){
                this.itemSlopeMap = response.data;
              }
           
              
            }
      })
      .catch(error => { console.log(error)})
    },
  },
}


</script>

<style lang="css">

</style>