<template>
  <div>
    <PageHeader :img="img" subheading="Historical Background, Mayors, Geography, Hymn"/>
    
    <v-container>
     
      <v-row>
        <v-col cols="12" md="12">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab
              v-for="(item, index) in items"
              :key="index"
              class="title"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(item, index) in items"
              :key="index"
            >
              <v-card
                color="basil"
                flat
              >
                <v-card-text>
                  <v-divider></v-divider>
                  <p v-if="item.title=='Hymn'"><MunicipalHymn/></p>
                  <p v-else-if="item.title=='Barangays'"><Barangays/></p>
                  <p v-else-if="item.title=='Mayors'"><Mayors/></p>
                  <p v-else-if="item.title=='Geography'"><Geography/></p>
                  <p v-else v-html="item.text" class="text-sm-caption text-md-h6 text-justify"></p>
                   <v-divider></v-divider>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <Departments/>
    </v-container>
    
 
    <Footer/>
  </div>
</template>

<script>

import axios from 'axios' 
import PageHeader from '@/components/PageHeader'
import Barangays from '@/views/Barangays'
import Mayors from '@/components/Mayors'
import Geography from '@/components/Geography'
import Departments from '@/views/DepartmentsNew'
import MunicipalHymn from '@/components/Sidebar/MunicipalHymn'
import Footer from '@/components/Footer'

export default {
  name : 'About',
  components: {
    PageHeader,
    Barangays,
    MunicipalHymn,
    Mayors,
    Geography,
    Departments,
    Footer,
   
  },
  data () {
    return {
      img: 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
     
      tab: null,
      items: [
        {
          'title' : 'Historical Background',
          'main_image': 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
          'text' : `<p>The&nbsp;Municipality now known as San Jose 
          was once a wilderness. Wild animals such as buffalo, birds, and flora abound this place.</p> 
          
          <p>The aetas&nbsp;were the first inhabitants of the place. 
          They started clearing some portions of the wilderness especially in areas near the rivers. 
          Several groups of settlers then drove this aetas to the hinterlands and 
          permanently occupied the place. 
          They named it&nbsp;<i>“Malaking Tubig”</i>&nbsp;referring to the big river crossing the 
          central portion of their settlement.</p>
          </p>
          <p>The Spaniards then colonized the Philippines.&nbsp;
          <a href="https://en.wikipedia.org/wiki/Bauan,_Batangas" class="mw-redirect" title="Bauan, Batangas" style="color: rgb(6, 69, 173); background: none;">Bauan</a> 
          (a nearby town of the Municipality of San jose)&nbsp;
          was established in 1596 as an ecclesiastical unit administered by the order of Saint Agustin with "Malaking Tubig" as one of the barrios under its jurisdiction.</p>
          </p>
          <p>Human population of Malaking Tubig started to grow in 1754,&nbsp;
          <a href="https://en.wikipedia.org/wiki/Taal_Volcano" title="Taal Volcano" style="color: rgb(6, 69, 173); background: none;">
          Taal Volcano</a>&nbsp;erupted destroying the original Bauan. And before its actual site could have been selected, Malaking Tubig was separated from Bauan. 
          The recognized leaders of Malaking Tubig then petitioned to the Spanish authorities for the creation of that place as a pueblo which was granted to them on April 26, 1765.</p>
          </p>
          <p>Two year later, Malaking tubig was established into a pueblo where 
          <b>Ignacio de los Santos( also known as Ignacio Ona )</b> became the <i>Gobernadorcillo</i>. 
          Included in the inaugural jurisdiction were the present administrative units, the Poblacion
          and the barrios of Labac, Dita, Ibabao, Dalipit and Makulot.  
          The last six barrios were also separated through a petition which was approved on March 13, 1877 to become a new town of
          <a href="https://en.wikipedia.org/wiki/Cuenca,_Batangas" title="Cuenca, Batangas" style="color: rgb(6, 69, 173); background: none;">Cuenca</a>.</p>
          
        `

        },
        {
          'title' : 'Mayors',
          'main_image': 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
          'text' : `<p style="margin: 0.5em 0px; color: rgb(32, 33, 34);  font-size: 14px;"></p>`
        },
        {
          'title' : 'Barangays',
          'main_image': 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
          'text' : `<p style="margin: 0.5em 0px; color: rgb(32, 33, 34);  font-size: 14px;"></p>`
        },
        {
          'title' : 'Geography',
          'main_image': 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
        
        },
               
        {
          'title' : 'Hymn',
          'main_image': 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
          'text' : `<p >The&nbsp;</p>`
        }
      ],
    }
  },

  mounted(){
     
  
    //alert(this.MayorsData);
    },  

  methods: {
    getAllRecords:function(query,table){
      axios.get( this.$api_url+query,{
       
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(table=='mayors'){
                  this.MayorsData=response.data
                  //alert(this.MayorsData);
              }
            
              
              
            }
      })
      .catch(error => { console.log(error)})
    
    },  
  }

}
</script>

<style lang="scss">
  
</style>