<template>
  <div class="text-sm-caption text-md-h6 text-justify">
   
    <v-list-item three-line>
      <v-list-item-content class="text-center">
        <v-list-item-title class="font-weight-bold text-md-h5">Himno ng San Jose Batangas</v-list-item-title>
        <v-list-item-subtitle>
          Lyrics:  Mr. Alden M. Limbo
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Artist/Singer:  Mrs. Amelia M. Ilagan
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Accompanist:  David Alday
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content class="text-center font-weight-bold">
        <v-list-item-subtitle class="text-md-h5">
          Nang likhain ang sandaigdigan,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Itinanghal kang aming bayan.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Sa piling mo at kandungan
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Katauha'y dinalisay
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Masinop na pamumuhay
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Handog ay pagmamahal,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Pagkamit ng karunungan,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Kami ay 'yong pinanday.
        </v-list-item-subtitle>
        

        <v-list-item-subtitle class="text-md-h5">
          Pinagmulan mo O aking bayan
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Malaking tubig ng Bauan
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Biyaya ng kasarinlan
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Amin 'tong pinagyayaman
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Buong buhay, buong laya,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Nais naming ialay.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Atin nang pagsumikapan
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Lahat ay magtulungan.
        </v-list-item-subtitle>
        
        <v-list-item-subtitle class="text-md-h5">
          San Jose O bayan ko,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Dinadakilang totoo.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Kalinisan at kapayapaan,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Hangad ng buong bayan.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Maging itong mga kabataan,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Sandigan sa kaunlaran.
        </v-list-item-subtitle>

        <v-list-item-subtitle class="text-md-h5">
          San Jose O bayan ko,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Dinadakilang totoo.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Kalinisan at kapayapaan,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Hangad ng buong bayan.
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Sa may kapal ay ating ialay,
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-md-h5">
          Makataong kaunlaran.
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>

export default {
  name : 'MunicipalHymm',
  components : {
    
  }
}
</script>

<style lang="scss">
  
</style>